.hidden {
  display: none; }

.hideslow {
  opacity: 0 !important; }

.showslow {
  opacity: 1 !important; }

.disabled {
  color: #333;
  cursor: default !important; }

a {
  transition: color .2s ease-in-out, opacity .2s ease-in-out; }

p, .how h3 {
  font-size: 1.1em; }

input {
  -webkit-appearance: none;
  border-radius: 0; }

.right {
  text-align: right; }

.center {
  text-align: center; }

#header, .desc {
  overflow: hidden; }

.scrl {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  transition: opacity .3s ease-in-out;
  opacity: 0; }
  .scrl .icon-scroll {
    padding: 0px 10px;
    vertical-align: middle;
    font-size: 2em; }

.hand {
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 1.6em; }
  .hand p, .hand .how h3, .how .hand h3 {
    display: block !important;
    margin-top: 480px;
    padding: 0; }
    .hand p:last-child, .hand .how h3:last-child, .how .hand h3:last-child {
      margin-bottom: 480px; }
  .hand b {
    font-weight: 400; }
  .hand .drawing {
    position: absolute;
    top: -610px;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    height: 610px;
    width: 959px;
    background-size: 100%;
    transition: top .6s;
    z-index: -1; }
    .hand .drawing .flmprt {
      position: relative;
      height: 610px; }
      .hand .drawing .flmprt .flment {
        position: absolute;
        bottom: 37px;
        left: 2px;
        width: 5px;
        height: 2700px;
        margin-left: -2px;
        background-color: #323232;
        border-bottom-left-radius: 3px;
        z-index: -3; }
      .hand .drawing .flmprt .drwlix {
        height: 610px;
        width: 959px;
        background-image: url("../images/hand.png"); }

.gallery {
  position: relative;
  height: 580px;
  margin-top: -56px;
  overflow: hidden;
  background: url("../images/galTable.png") repeat-x center bottom; }
  .gallery .images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: left .8s ease-in-out; }
    .gallery .images * {
      display: inline-block;
      content: "";
      height: 580px;
      width: 100%;
      background-position: center top;
      background-repeat: no-repeat; }
    .gallery .images .image {
      display: inline-block;
      transition: opacity .6s ease-in-out; }
    .gallery .images .image0 {
      background-image: url("../images/gallery/00.png"); }
    .gallery .images .image1 {
      background-image: url("../images/gallery/01.png"); }
    .gallery .images .image2 {
      background-image: url("../images/gallery/02.png"); }
    .gallery .images .image3 {
      background-image: url("../images/gallery/03.png"); }
    .gallery .images .image4 {
      background-image: url("../images/gallery/04.png"); }
  .gallery .arrow-left, .gallery .arrow-right {
    height: 540px;
    font-size: 4em; }
    .gallery .arrow-left a, .gallery .arrow-right a {
      display: block;
      content: "";
      height: 100%;
      width: 100%;
      padding: 200px 0 0 0; }
    .gallery .arrow-left .disabled, .gallery .arrow-right .disabled {
      cursor: default;
      color: #b2b2b2; }
      .gallery .arrow-left .disabled:hover, .gallery .arrow-right .disabled:hover {
        color: #b2b2b2; }

.lixh {
  position: relative;
  padding: 250px 0;
  overflow: hidden; }
  .lixh .lixpen {
    position: absolute;
    top: 50%;
    right: 100%;
    width: 699px;
    height: 73px;
    margin-top: -36px;
    background: url("../images/lixPenH.png") no-repeat;
    transition: right .9s ease-in-out; }
  .lixh p, .lixh .how h3, .how .lixh h3 {
    margin: 0; }

h2.red, .desc h3.red {
  font-weight: 400;
  color: #df5a49; }

h2.grey, .desc h3.grey {
  background-color: rgba(227, 227, 227, 0.7); }

.techno {
  background-color: #e3e3e3;
  padding: 55px 0; }
  .techno .icos {
    text-align: center;
    font-size: 8em;
    opacity: .7; }
    .techno .icos a:hover {
      color: #323232; }
  .techno p, .techno .how h3, .how .techno h3 {
    text-align: center; }

.fil {
  padding: 110px 0 60px 0;
  background: url("../images/fil.png") no-repeat -50px bottom; }
  .fil b {
    font-weight: 500; }

.how {
  position: relative;
  background-color: #e3e3e3;
  overflow: hidden;
  text-align: center;
  padding-bottom: 100px; }
  .how h2, .how .desc h3, .desc .how h3 {
    padding: 50px 0;
    color: #df5a49;
    border-bottom: 1px solid #d7d7d7;
    margin: 0; }
  .how h2.latest, .how .desc h3.latest, .desc .how h3.latest {
    margin-top: 50px;
    padding: 50px 0;
    color: #df5a49;
    border-top: 1px solid #d7d7d7;
    border-bottom: none; }
  .how p, .how h3 {
    margin: 0; }
  .how h3 {
    margin-top: 40px;
    font-weight: 400; }
  .how .icns {
    margin-top: -20px; }
    .how .icns .sprt-one, .how .icns .sprt-two, .how .icns .sprt-three {
      display: inline-block;
      width: 40px;
      height: 40px;
      background-image: url("../images/sprite.png");
      background-position: 0px 120px; }
    .how .icns .sprt-two {
      background-position: 0px 80px; }
    .how .icns .sprt-three {
      background-position: 0px 40px; }
  .how .energy, .how .wait, .how .enjoy {
    display: inline-block;
    height: 129px;
    width: 54px;
    margin-top: 17px;
    background-image: url("../images/spritehwt.png");
    background-position: 0 0; }
  .how .wait {
    height: 146px;
    width: 125px;
    margin-top: 0;
    background-position: 0 146px; }
  .how .enjoy {
    height: 127px;
    width: 106px;
    margin-top: 19px;
    background-position: 0 273px; }

.desc {
  padding: 65px 0;
  background-color: #df5a49; }
  .desc * {
    color: #fff; }
    .desc *:hover {
      color: #fff; }
  .desc h2, .desc h3 {
    margin-bottom: 30px;
    text-align: center; }
  .desc h3 {
    font-size: 1.2em; }
  .desc .schema {
    position: relative;
    margin: auto;
    width: 624px;
    height: 728px;
    background: url("../images/lix2lines.png") no-repeat center center; }
    .desc .schema .pos {
      position: absolute;
      top: 30px;
      left: 279px; }
    .desc .schema a {
      display: inline-block;
      position: absolute;
      font-size: .9em;
      transition: left .3s ease-in-out, right .3s ease-in-out; }
      .desc .schema a b {
        font-weight: 400; }
      .desc .schema a.one, .desc .schema a.two, .desc .schema a.fou, .desc .schema a.six, .desc .schema a.eig, .desc .schema a.ele {
        top: -10px;
        left: 450px; }
      .desc .schema a.thr, .desc .schema a.sev, .desc .schema a.nin, .desc .schema a.ten {
        top: 108px;
        right: 445px;
        text-align: right; }
      .desc .schema a.two {
        top: 40px; }
      .desc .schema a.fou {
        top: 166px; }
      .desc .schema a.six {
        top: 401px; }
      .desc .schema a.eig {
        top: 534px; }
      .desc .schema a.ele {
        top: 712px; }
      .desc .schema a.sev {
        top: 549px; }
      .desc .schema a.nin {
        top: 650px; }
      .desc .schema a.ten {
        top: 703px; }
      .desc .schema a.fiv {
        top: 365px; }
    .desc .schema svg path, .desc .schema svg line, .desc .schema svg polyline {
      stroke: #fff;
      stroke-width: 1px; }
    .desc .schema svg path, .desc .schema svg line, .desc .schema svg polyline {
      fill: transparent; }

.clrs {
  background-color: #323232;
  padding: 125px 0;
  overflow: hidden; }
  .clrs h2, .clrs .desc h3, .desc .clrs h3 {
    text-transform: none;
    font-size: 1em;
    margin: 30px 0 0 0; }
  .clrs p, .clrs .how h3, .how .clrs h3 {
    margin: 0; }
    .clrs p.discount, .clrs .how h3.discount, .how .clrs h3.discount {
      font-size: 1.3em;
      font-weight: 600;
      color: #878787;
      margin-bottom: 25px; }
      .clrs p.discount .barre, .clrs .how h3.discount .barre, .how .clrs h3.discount .barre {
        text-decoration: line-through; }
  .clrs a {
    font-weight: 400 !important;
    color: #23527c; }
  .clrs .icos {
    font-size: 1.1em;
    padding: 0 5px;
    margin: 0 5px;
    opacity: .7; }
    .clrs .icos:last-child {
      margin: 0;
      padding-right: 0; }
  .clrs * {
    color: #fafafa;
    -webkit-font-smoothing: antialiased; }
  .clrs .row {
    position: relative;
    min-height: 628px; }
    .clrs .row.order {
      position: relative;
      min-height: 628px; }
  .clrs .dlvr {
    margin-top: 15px; }
  .clrs .btn {
    padding: 10px 16px; }
  .clrs .btnclrs {
    line-height: 5px; }
    .clrs .btnclrs span {
      display: inline-block;
      margin-bottom: 10px; }
    .clrs .btnclrs .btn {
      border: 2px solid transparent;
      padding: 10px 10px;
      margin: 0 5px 0 0; }
      .clrs .btnclrs .btn.btn-black, .clrs .btnclrs .btn.btn-black:hover {
        background: #262626;
        transition: all .3s ease-in-out; }
        .clrs .btnclrs .btn.btn-black:hover {
          border: 2px solid #df5a49; }
      .clrs .btnclrs .btn.btn-white, .clrs .btnclrs .btn.btn-white:hover {
        background: #c5c5c5;
        transition: all .3s ease-in-out; }
        .clrs .btnclrs .btn.btn-white:hover {
          color: #323232;
          border: 2px solid #df5a49; }
      .clrs .btnclrs .btn.slctd {
        border: 2px solid #df5a49 !important; }
  .clrs .line {
    clear: both;
    padding: 20px 0;
    border-bottom: 1px solid #c3c3c3;
    vertical-align: middle; }
    .clrs .line.title {
      height: auto;
      margin-top: 20px;
      color: #999; }
    .clrs .line .ttl {
      margin-top: 20px;
      color: #999; }
    .clrs .line * {
      white-space: nowrap;
      vertical-align: middle; }
    .clrs .line .val {
      min-height: 100px; }
    .clrs .line .img, .clrs .line .inBl {
      display: inline-block;
      height: 100px;
      width: 100px;
      background-color: #fefefe;
      border-radius: 3px; }
    .clrs .line .inBl {
      background-color: inherit;
      border-radius: none;
      width: 0; }
    .clrs .line h2, .clrs .line .desc h3, .desc .clrs .line h3 {
      margin: 0 0 0 50px;
      display: inline-block; }
    .clrs .line .qnt .btn-grey {
      border: 2px solid #c3c3c3;
      border-left: none;
      border-right: none;
      border-radius: 0px;
      background-color: none;
      color: #323232; }
      .clrs .line .qnt .btn-grey:first-of-type {
        border-radius: 5px 0 0 5px;
        border-left: 2px solid #c3c3c3; }
      .clrs .line .qnt .btn-grey:last-child {
        border-radius: 0 5px 5px 0;
        border-right: 2px solid #c3c3c3; }
      .clrs .line .qnt .btn-grey:hover {
        background-color: #c3c3c3;
        color: #df5a49; }
      .clrs .line .qnt .btn-greya:hover {
        color: #323232; }
    .clrs .line.total h6 {
      margin: 30px 0;
      font-size: 1.6em;
      font-weight: 400;
      line-height: 1em; }
    .clrs .line.total .note {
      white-space: normal;
      margin: 20px 0;
      color: #999; }
    .clrs .line:last-child {
      border-bottom: none; }
  .clrs .addInfo * {
    margin: 15px 0; }
  .clrs .addInfo p, .clrs .addInfo .how h3, .how .clrs .addInfo h3 {
    margin: 5px 0 !important; }
  .clrs .pen {
    position: absolute;
    left: 0;
    width: 621px;
    height: 628px;
    transition: left .6s ease-in-out, opacity .3s ease-in-out;
    opacity: 1; }
    .clrs .pen.penw {
      background: url("../images/penw.png") no-repeat;
      background-size: 100%; }
    .clrs .pen.penb {
      background: url("../images/penb.png") no-repeat;
      background-size: 100%; }
    .clrs .pen.out {
      opacity: 0;
      left: -200px; }
  .clrs .dlvr {
    margin-top: 15px; }
  .clrs .btnclrs {
    line-height: 5px; }
    .clrs .btnclrs .btn {
      border: 1px solid #c5c5c5;
      padding: 10px 10px;
      margin-right: 5px; }
      .clrs .btnclrs .btn.btn-black, .clrs .btnclrs .btn.btn-black:hover {
        background: #262626;
        transition: all .3s ease-in-out; }
        .clrs .btnclrs .btn.btn-black:hover {
          border: 1px solid #df5a49; }
      .clrs .btnclrs .btn.btn-white, .clrs .btnclrs .btn.btn-white:hover {
        background: #c5c5c5;
        transition: all .3s ease-in-out; }
        .clrs .btnclrs .btn.btn-white:hover {
          border: 1px solid #df5a49; }
      .clrs .btnclrs .btn.slctd {
        border: 1px solid #df5a49 !important; }
  .clrs .sepb {
    margin: 20px 0 !important;
    border-bottom: 1px solid #c3c3c3 !important; }
  .clrs .sep {
    margin: 20px 0;
    border-bottom: 1px solid #797979; }
  .clrs table {
    display: inline-block;
    line-height: 1em;
    text-align: left; }
    .clrs table.price {
      padding: 13px 0; }
    .clrs table .dvs {
      vertical-align: top; }
    .clrs table .prx {
      font-size: 2.2em; }
    .clrs table .rwdvs {
      color: #606060; }

.ftd {
  background: #292929;
  padding: 20px 0 20px 0;
  text-align: center; }
  .ftd a {
    display: inline-block;
    content: "";
    width: 12.3%;
    height: 36px;
    opacity: .8;
    transition: opacity .3s ease-in-out; }
    .ftd a:hover {
      opacity: 1; }
    .ftd a.bsin {
      background: url("../images/ftd/bsin.png") no-repeat center center; }
    .ftd a.engd {
      background: url("../images/ftd/engd.png") no-repeat center center; }
    .ftd a.mlon {
      background: url("../images/ftd/mlon.png") no-repeat center center; }
    .ftd a.mshb {
      background: url("../images/ftd/mshb.png") no-repeat center center; }
    .ftd a.wrd {
      background: url("../images/ftd/wird.png") no-repeat center center; }
    .ftd a.entr {
      background: url("../images/ftd/entr.png") no-repeat center center; }
    .ftd a.tchc {
      background: url("../images/ftd/tchc.png") no-repeat center center; }
    .ftd a.clsl {
      background: url("../images/ftd/clsl.png") no-repeat center center; }

.ftr {
  padding: 45px 0;
  background: #202020;
  color: #fafafa; }
  .ftr * {
    color: #fafafa !important; }
  .ftr p, .ftr .how h3, .how .ftr h3 {
    margin: 0;
    padding: 0;
    font-size: .8em;
    opacity: .2; }
  .ftr .icons {
    margin: 25px 0 20px 0; }
  .ftr .icon {
    margin: 0 30px 0 0;
    font-size: 1.3em;
    opacity: .3; }
  .ftr a {
    opacity: .7; }
    .ftr a:hover {
      opacity: 1; }
  .ftr .popLinks {
    margin: 0 0 10px 0;
    color: #7e7e7e !important; }
    .ftr .popLinks * {
      font-size: .9em;
      opacity: .3; }
  .ftr h2, .ftr .desc h3, .desc .ftr h3 {
    margin: 0 0 20px 0;
    line-height: 1em;
    font-size: 1.5em; }
  .ftr input {
    margin: 1px;
    transition: all .3s ease-in-out;
    outline: 0; }
  .ftr input[type=email] {
    padding: 15px 0;
    background-color: #202020;
    border: 1px solid #323232;
    border-radius: 3px;
    text-align: center;
    color: #3d3d3d; }
    .ftr input[type=email]:focus {
      background-color: #191919; }
  .ftr input[type=submit] {
    cursor: pointer; }

.sep {
  margin: 10px 0; }

@-ms-viewport {
  width: device-width; }

@media (max-width: 767px) {
  #header h1 {
    margin-top: 240px !important; }
  #header .flLix {
    bottom: 115px !important; }
  #header .btns {
    margin-top: 640px; }
  .fil {
    background-position: -445px bottom; }
  .how .elems {
    font-size: 25em !important; }
    .how .elems.energy, .how .elems.wait, .how .elems.enjoy {
      top: 160px;
      left: -160px !important; }
    .how .elems.wait {
      font-size: 20em !important; }
    .how .elems.enjoy {
      font-size: 28em !important; }
  .clrs .row {
    min-height: 418px !important; }
  .clrs .pen {
    width: 414px !important;
    height: 418px !important; }
  .clrs .btnclrs .btn {
    padding: 20px 0;
    width: 100%; }
  .xs-center {
    text-align: center; }
  .ftr input[type=submit] {
    margin-top: 20px; }
  .ftr p, .ftr .how h3, .how .ftr h3, .ftr .popLinks {
    font-size: 1em; }
    .ftr p *, .ftr .how h3 *, .how .ftr h3 *, .ftr .popLinks * {
      font-size: 1em; } }

@media (min-width: 768px) and (max-width: 991px) {
  .fil {
    background-position: -400px bottom; }
  .clrs .row {
    min-height: 570px !important; }
  .clrs .pen {
    width: 564px !important;
    height: 570px !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .fil {
    background-position: -300px bottom; } }

@media (min-width: 1200px) {
  .fil {
    background-position: -200px bottom; } }

@media (max-width: 767px) {
  .ftd a {
    width: 20% !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .ftd a {
    width: 16.6% !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .ftd a {
    width: 14.27% !important; } }

.popupWindow {
  transition: opacity .3s;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  -webkit-transform: translate3d(0, 0, 0);
  /* FIX BUG WITH VIEW ON POPUP */
  text-align: center; }
  .popupWindow.on {
    opacity: 1; }
  .popupWindow.off {
    opacity: 0; }
  .popupWindow .popupClose {
    transition: color .3s;
    position: absolute;
    top: 0;
    right: 0;
    margin: -25px -25px 0 0;
    cursor: pointer;
    font-size: 30px;
    color: #fff;
    z-index: 999; }
    .popupWindow .popupClose:hover {
      color: #df5a49; }
  .popupWindow .popupContent {
    position: relative;
    margin: 0 auto;
    padding: 5px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #000;
    border-radius: 3px;
    line-height: 0;
    width: 100%;
    max-width: 1199px; }
    .popupWindow .popupContent.none {
      border: none;
      background: none; }
    .popupWindow .popupContent .wrap {
      position: relative;
      width: 100%;
      height: 0;
      overflow: hidden;
      padding-top: 56%;
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll; }
      .popupWindow .popupContent .wrap img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    .popupWindow .popupContent iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
